import { Alert } from './Alert'
import { Cargo } from './Cargo'
import { Model } from '@/models/Model'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { TransportLocation } from '@/models/TransportLocation'
import TransportStatus from '@/enums/TransportStatus'
import { Vessel } from '@/models/Vessel'

export class FlatTransport extends Model {
	protected jsonApiType: string = 'flatTransports'
	protected static pageSize = 10

	public alerts(): ToManyRelation {
		return this.hasMany(Alert, 'alerts')
	}

	public getAlerts(): Array<Alert> {
		return this.getRelation('alerts') || []
	}

	public transportLocations(): ToManyRelation {
		return this.hasMany(TransportLocation, 'transportLocations')
	}

	public getLocations(): Array<TransportLocation> {
		return [...(this.getRelation('transportLocations') || [])].sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
	}

	public getActiveLocation(): TransportLocation {
		const locations = this.getLocations()
		const filledStatuses = locations.filter((location) => location.status !== null)
		return filledStatuses[filledStatuses.length - 1] || locations[0]
	}

	public getNextLocation(): TransportLocation | null {
		const active = this.getActiveLocation()
		return this.getLocations().find((loc) => loc.sequence === active.sequence + 1) || null
	}

	public async fetchCargoLazy(): Promise<Cargo> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transports/hasRelationCached'](this.getApiId(), 'cargo')) {
			const cargo = (await Cargo.find(this.cargoId)).getData() as Cargo
			store.commit('transports/cacheRelationship', {
				id: this.getApiId(),
				relationName: 'cargo',
				values: cargo
			})
		}

		return store.getters['transports/peekRelation'](this.getApiId(), 'cargo')
	}

	public async fetchVesselLazy(): Promise<Vessel> {
		const store = (await import('@/store/index')).store
		if (!store.getters['transports/hasRelationCached'](this.getApiId(), 'vessel') && this.vesselId) {
			const vessel = (await Vessel.find(this.vesselId as string)).getData() as Vessel
			store.commit('transports/cacheRelationship', {
				id: this.getApiId(),
				relationName: 'vessel',
				values: vessel
			})
		}

		return store.getters['transports/peekRelation'](this.getApiId(), 'vessel')
	}

	@Property()
	public generateBillOfLading!: boolean

	@Property()
	public generateHopperMeasurementsReport!: boolean

	@Property()
	public unloadingBillAttached!: boolean

	@Property()
	public trackTraceSent!: boolean

	@Property()
	public status!: TransportStatus | null

	@Property()
	public statusExcerpt!: TransportStatus | null

	@Property()
	public title!: string | null

	@Property()
	public subTitle!: string | null

	@Property()
	public vesselId!: string | null

	@Property()
	public startTerminalId!: string | null

	@Property()
	public destinationTerminalId!: string | null

	@Property()
	public clientId!: string | null

	@Property()
	public clientName!: string | null

	@Property()
	public dossierNumber!: string

	@Property()
	public clientOrderReference!: string

	@Property()
	public productExcerpt!: string

	@Property()
	public loadingDate!: string

	@Property()
	public unloadingDate!: string

	@Property()
	public startLocationExcerpt!: string

	@Property()
	public destinationLocationExcerpt!: string

	@Property()
	public cargoId!: string

	@Property()
	public offerId!: string

	@Property()
	public bidId!: string

	private externalTransport(): ToOneRelation {
		return this.hasOne(FlatTransport, 'externalTransport')
	}

	public getExternalTransport(): FlatTransport {
		return this.getRelation('externalTransport')
	}

	private ownTransport(): ToOneRelation {
		return this.hasOne(FlatTransport, 'ownTransport')
	}

	public getOwnTransport(): FlatTransport {
		return this.getRelation('ownTransport')
	}

	public get hasAlerts(): boolean {
		return this.getAlerts().length > 0
	}

	public get hasActiveAlerts(): boolean {
		return this.getAlerts().filter((alert) => alert.isActive).length > 0
	}

	public isLoading: boolean = false
	public hasDroppedFile: boolean = false
}
