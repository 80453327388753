import Auth from '@aws-amplify/auth'
import { BillOfLading } from '@/models/BillOfLading'
import { Document } from '@/models/Document'
import { Model } from 'coloquent'
import { Permission } from '@/models/Permission'
import Router from 'vue-router'
import User from '@/models/User'
import Vue from 'vue'
import axios from 'axios'
import { store } from '@/store'

Vue.use(Router)

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
	return new Promise((resolve, reject) => {
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') ?? 'false')
		// try to import the component
		componentImport()
			.then((component) => {
				window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
				resolve(component)
			})
			.catch((error) => {
				if (!hasRefreshed) {
					// not been refreshed yet
					window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
					return window.location.reload() // refresh the page
					// alert('test')
				}
				reject(error) // Default error behaviour as already tried refresh
			})
	})
}

const addAuthHeader = (userAuth): void => {
	Model.getHttpClient().getImplementingClient().defaults.headers.Authorization = `Bearer ${userAuth.signInUserSession.accessToken.jwtToken}`
	// @ts-ignore
	axios.defaults.headers.Authorization = `Bearer ${userAuth.signInUserSession.accessToken.jwtToken}`
}
const pushUserDataToGoogleTagManager = (user): void => {
	if ((window as any).dataLayer.find((data) => data.logged_in_id) === undefined) {
		;(window as any).dataLayer.push({
			logged_in_id: user.getApiId(),
			logged_in_name: [user.firstName, user.lastName].join(' '),
			email: user.email,
			company_str: user.companyName,
			segment: user.role
		})
	}
}

const getUser = async (): Promise<object | null> => {
	try {
		const data = await Auth.currentAuthenticatedUser()

		if (data?.signInUserSession) {
			addAuthHeader(data)

			const shouldForceSync = !store.getters['auth/isInitialized']

			await Promise.all([Permission.sync(), User.sync(shouldForceSync)])

			const user = await User.getCurrentUser()
			pushUserDataToGoogleTagManager(user)

			return data
		}
	} catch (e) {
		if (e !== 'The user is not authenticated') {
			// eslint-disable-next-line
			console.error(e)
		}
	}

	return null
}

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			redirect: () => {
				const firstAllowed = store.getters['auth/navigationItems'][0]
				return { name: firstAllowed ? firstAllowed.routeName : 'map' }
			},
			meta: {
				auth: true
			}
		},
		{
			path: '/language',
			name: 'language',
			component: () => import('@/views/Language.vue'),
			meta: {
				auth: true
			}
		},
		{
			path: '/map/:category?',
			name: 'map',
			component: () => lazyRetry(() => import(/* webpackChunkName: "map" */ '@/views/Map.vue')),
			meta: {
				auth: true,
				permission: 'maps.index'
			},
			children: [
				{
					path: '/map/:category/:id',
					name: 'map.facility',
					props: true,
					component: () => lazyRetry(() => import(/* webpackChunkName: "map" */ '@/views/Map/Facility.vue'))
				},
				{
					path: '/map/directions',
					name: 'map.directions',
					props: true,
					component: () => lazyRetry(() => import(/* webpackChunkName: "map" */ '@/views/Map/Directions.vue'))
				}
			]
		},
		{
			path: '/cargo',
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Cargos.vue')),
			redirect: 'cargo',
			meta: {
				auth: true,
				permission: 'offers.index'
			},
			children: [
				{
					path: '',
					name: 'cargo',
					component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Index.vue'))
				},
				{
					name: 'cargo.owned',
					path: 'owned',
					redirect: { name: 'cargo.owned.index' },
					components: {
						default: () =>
							lazyRetry(
								() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/OwnedPlaceholder.vue')
							),
						toolbar: () =>
							lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/UserFilter.vue'))
					},
					children: [
						{
							path: '',
							name: 'cargo.owned.index',
							components: {
								default: () =>
									lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Owned.vue')),
								toolbar: () =>
									lazyRetry(
										() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/UserFilter.vue')
									)
							}
						},
						{
							name: 'cargo.owned.scheduled',
							path: 'scheduled',
							components: {
								default: () =>
									lazyRetry(
										() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Scheduled.vue')
									),
								toolbar: () =>
									lazyRetry(
										() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/UserFilter.vue')
									)
							}
						}
					]
				},
				{
					name: 'cargo.projects',
					path: 'projects',
					redirect: { name: 'cargo.projects.index' },
					components: {
						default: () =>
							lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Projects.vue')),
						toolbar: () =>
							lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/UserFilter.vue'))
					},
					children: [
						{
							path: '',
							name: 'cargo.projects.index',
							components: {
								default: () =>
									lazyRetry(
										() =>
											import(
												/* webpackChunkName: "cargo" */ '@/views/Cargo/CargoProjectsIndex.vue'
											)
									),
								toolbar: () =>
									lazyRetry(
										() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/UserFilter.vue')
									)
							}
						},
						{
							path: 'scheduled',
							name: 'cargo.projects.scheduled',

							components: {
								default: () =>
									lazyRetry(
										() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/CargoProjects.vue')
									),
								toolbar: () =>
									lazyRetry(
										() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/UserFilter.vue')
									)
							}
						}
					]
				},
				{
					name: 'cargo.external',
					path: 'external',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/External.vue'))
				}
			]
		},
		{
			path: '/cargo/project/options',
			name: 'cargo.project.options',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/CargoProject/CargoProjectOptions.vue')),
			meta: {
				auth: true,
				permission: 'cargoProjects.store'
			}
		},
		{
			path: '/cargo/project/:cargoProjectId/edit',
			name: 'cargo.project.edit',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/CargoProject/CargoProjectEdit.vue')),
			meta: {
				auth: true,
				permission: 'cargoProjects.update'
			}
		},
		{
			path: '/cargo/project/:cargoProjectId/cargo-blueprint',
			name: 'cargo.project.cargo-blueprint',
			props: true,
			component: () =>
				lazyRetry(
					() => import(/* webpackChunkName: "cargo" */ '@/views/CargoProject/CargoProjectBlueprint.vue')
				),
			meta: {
				auth: true,
				permission: 'cargoProjects.store'
			}
		},
		{
			path: '/cargo/project/:cargoProjectId',
			name: 'cargo.project.show',
			props: true,
			component: () =>
				lazyRetry(
					() => import(/* webpackChunkName: "cargo" */ '@/views/CargoProject/ViewCargoProjectDetails.vue')
				),
			meta: {
				auth: true,
				permission: 'cargoProjects.show'
			}
		},
		{
			path: '/cargo/:cargoId/edit',
			name: 'cargo.edit',
			props: true,
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/EditCargo.vue')),
			meta: {
				auth: true,
				permission: 'cargos.update'
			}
		},

		{
			path: '/cargo/offers',
			name: 'cargo.offers.index',
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/CargoOffers.vue')),
			meta: {
				auth: true,
				additional_details_required: true,
				permission: 'offers.index'
			}
		},
		{
			path: '/cargo/offers/:cargoId',
			name: 'cargo.offers.show',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/ViewCargoDetails.vue')),
			meta: {
				auth: true,
				additional_details_required: true,
				permission: 'offers.show'
			}
		},
		{
			path: '/cargo/offers/:cargoId/offer-details',
			name: 'cargo.offers.details',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/ViewOfferDetails.vue')),
			meta: {
				auth: true,
				permission: 'cargos.show'
			}
		},
		{
			path: '/cargo/offers/:cargoId/empty-vessels',
			name: 'cargo.empty-vessels.index',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/ViewEmptyVessels.vue')),
			meta: {
				auth: true,
				permission: 'empties.index'
			}
		},
		{
			path: '/cargo/offers/:cargoId/replies/:replyType/:replyId',
			name: 'cargo.reply.show',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/ViewOfferReply.vue')),
			meta: {
				auth: true,
				permission: 'cargos.store'
			}
		},
		{
			path: '/cargo/offers/:cargoId/replies/:replyType/:replyId/preview-charter',
			name: 'cargo.reply.preview-charter',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/PreviewCharter.vue')),
			meta: {
				auth: true,
				permission: 'charter.store'
			}
		},
		{
			path: '/cargo/offers/:cargoId/replies/charters/:charterId/accept-charter',
			name: 'cargo.offers.accept-charter',
			props: true,
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/AcceptCharter.vue')),
			meta: {
				auth: true,
				permission: 'charter.update'
			}
		},

		{
			path: '/cargo/offers/:cargoId/replies/:replyType/:replyId/edit-charter',
			name: 'cargo.reply.edit-charter',
			props: true,
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/EditCharter.vue')),
			meta: {
				auth: true,
				permission: 'cargos.update'
			}
		},
		{
			path: '/cargo/options',
			name: 'cargo.options',
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/CargoOptions.vue')),
			props: true,
			meta: {
				auth: true,
				permission: 'cargos.store'
			}
		},
		{
			path: '/cargo/:cargoId/route',
			name: 'cargo.route.index',
			props: true,
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/RouteCompare.vue')),
			meta: {
				auth: true,
				permission: 'offers.show'
			}
		},
		{
			path: '/cargo/:cargoId/publish',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/CargoPublication.vue')),
			meta: {
				auth: true,
				additional_details_required: true,
				permission: 'offers.store'
			},
			children: [
				{
					path: '',
					name: 'cargo.route.publish.index',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Publication/Index.vue'))
				},
				{
					name: 'cargo.route.publish.tender',
					path: 'tender',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Publication/Tender.vue'))
				},
				{
					name: 'cargo.route.publish.schedule',
					path: 'schedule',
					component: () =>
						lazyRetry(
							() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/Publication/Schedule.vue')
						)
				}
			]
		},
		{
			path: '/cargo-stored',
			name: 'cargo.route.show',
			props: true,
			component: () => lazyRetry(() => import(/* webpackChunkName: "cargo" */ '@/views/Cargo/CargoStored.vue')),
			meta: {
				auth: true,
				permission: 'cargos.store'
			}
		},
		{
			path: '/messages',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "messages" */ '@/views/Messages/Conversations.vue')),
			meta: {
				auth: true,
				permission: 'messages.index'
			},
			children: [
				{
					path: '',
					name: 'messages',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "messages" */ '@/views/Messages/Index.vue'))
				},
				{
					path: '/messages/:conversationId',
					props: true,
					name: 'messages.conversation',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "messages" */ '@/views/Messages/Conversation.vue')),
					meta: {
						auth: true,
						permission: 'messages.show'
					}
				}
			]
		},
		{
			path: '/groups',
			component: () => lazyRetry(() => import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups.vue')),
			meta: {
				auth: true
			},
			children: [
				{
					path: '',
					name: 'groups',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups/Index.vue'))
				},
				{
					path: '/groups/favourite-vessels',
					name: 'groups.favourite-vessels',
					component: () =>
						lazyRetry(
							() =>
								import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups/FavouriteVessels.vue')
						),
					meta: {
						auth: true
					}
				},
				{
					path: '/groups/client-groups',
					name: 'groups.client-groups',
					component: () =>
						lazyRetry(
							() => import(/* webpackChunkName: "clientGroups" */ '@/views/Groups/ClientsGroups.vue')
						),
					meta: {
						auth: true
					}
				},
				{
					path: '/groups/favourite-brokers',
					name: 'groups.favourite-brokers',
					component: () =>
						lazyRetry(
							() =>
								import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups/FavouriteBrokers.vue')
						),
					meta: {
						auth: true
					}
				},
				{
					path: '/groups/fleets',
					name: 'groups.fleets',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups/Fleets.vue')),
					meta: {
						auth: true
					}
				},
				{
					path: '/groups/clients',
					name: 'groups.clients',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups/Clients.vue')),
					meta: {
						auth: true
					}
				},
				{
					path: '/groups/vessels',
					name: 'groups.vessels',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups/Vessels.vue')),
					meta: {
						auth: true
					}
				},
				{
					path: '/groups/brokers',
					name: 'groups.brokers',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "favouriteGroups" */ '@/views/Groups/Brokers.vue')),
					meta: {
						auth: true
					}
				}
			]
		},
		{
			path: '/favourite-groups/create',
			name: 'favourite-groups.create',
			component: () =>
				lazyRetry(
					() =>
						import(
							/* webpackChunkName: "favouriteGroups" */ '@/views/FavouriteGroups/CreateFavouriteGroup.vue'
						)
				),
			meta: {
				auth: true,
				permission: 'favouriteGroups.store'
			}
		},
		{
			path: '/favourite-groups/:favouriteGroupId',
			name: 'favourite-groups.show',
			component: () =>
				lazyRetry(
					() => import(/* webpackChunkName: "favouriteGroups" */ '@/views/FavouriteGroups/FavouriteGroup.vue')
				),
			props: true,
			meta: {
				auth: true,
				permission: 'favouriteGroups.show'
			}
		},
		{
			path: '/favourite-groups/:favouriteGroupId/addMember',
			name: 'favourite-groups.addMember',
			component: () =>
				lazyRetry(
					() =>
						import(
							/* webpackChunkName: "favouriteGroups" */ '@/views/FavouriteGroups/AddMemberToFavouriteGroup.vue'
						)
				),
			props: true,
			meta: {
				auth: true,
				permission: 'favouriteGroupMembers.store'
			}
		},
		{
			path: '/favourite-groups/:favouriteGroupId/editGroup',
			name: 'favourite-groups.editGroup',
			props: true,
			component: () =>
				lazyRetry(
					() =>
						import(
							/* webpackChunkName: "favouriteGroups" */ '@/views/FavouriteGroups/CreateFavouriteGroup.vue'
						)
				),
			meta: {
				auth: true,
				permission: 'favouriteGroupMembers.store'
			}
		},
		{
			path: '/client-groups/create',
			name: 'client-groups.create',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "clientGroups" */ '@/views/Groups/CreateClientGroup.vue')),
			meta: {
				auth: true
			}
		},
		{
			path: '/client-groups/:clientGroupId',
			name: 'client-groups.show',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "clientGroups" */ '@/views/Groups/ClientGroup.vue')),
			props: true,
			meta: {
				auth: true
			}
		},
		{
			path: '/client-groups/:clientGroupId/editClientGroup',
			name: 'client-groups.editClientGroup',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "clientGroups" */ '@/views/Groups/CreateClientGroup.vue')),
			meta: {
				auth: true
			}
		},
		{
			path: '/fleets/create',
			name: 'fleets.create',
			component: () => lazyRetry(() => import(/* webpackChunkName: "fleets" */ '@/views/Fleets/CreateFleet.vue')),
			meta: {
				auth: true,
				permission: 'fleets.store'
			}
		},
		{
			path: '/fleets/:fleetId',
			name: 'fleets.show',
			component: () => lazyRetry(() => import(/* webpackChunkName: "fleets" */ '@/views/Fleets/Fleet.vue')),
			props: true,
			meta: {
				auth: true,
				permission: 'fleets.show'
			}
		},
		{
			path: '/fleets/:fleetId/editFleet',
			name: 'fleets.editFleet',
			props: true,
			component: () => lazyRetry(() => import(/* webpackChunkName: "fleets" */ '@/views/Fleets/CreateFleet.vue')),
			meta: {
				auth: true,
				permission: 'fleets.store'
			}
		},
		{
			path: '/fleets/:fleetId/addMember',
			name: 'fleets.addMember',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "fleets" */ '@/views/Fleets/AddMemberToFleet.vue')),
			props: true,
			meta: {
				auth: true,
				permission: 'fleetMembers.store'
			}
		},
		{
			path: '/fleets/:fleetId/editMember/:fleetMemberId',
			name: 'fleets.editMember',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "fleets" */ '@/views/Fleets/EditFleetMember.vue')),
			props: true,
			meta: {
				auth: true,
				permission: 'fleetMembers.update'
			}
		},
		{
			path: '/transports',
			name: 'transports',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "transports" */ '@/views/Transport/Transports.vue')),
			props: true,
			meta: {
				auth: true,
				permission: 'transports.index'
			}
		},
		{
			path: '/transports/:transportId',
			name: 'transports.show',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "transports" */ '@/views/Transport/TransportDetail.vue')),
			meta: {
				auth: true,
				permission: 'transports.show',
				fetchResources: [new Document().getJsonApiType()]
			}
		},
		{
			path: '/vessel-logs/create-log-item',
			name: 'vessel-logs.create',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "vesselLogs" */ '@/views/Transport/CreateVesselLog.vue')),
			meta: {
				auth: true
				// permission: 'vessel-logs.store',
			}
		},
		{
			path: '/vessel-logs/edit-log-item/:vesselLogId',
			name: 'vessel-logs.edit',
			props: true,
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "vesselLogs" */ '@/views/Transport/CreateVesselLog.vue')),
			meta: {
				auth: true
				// permission: 'vessel-logs.update',
			}
		},
		{
			path: '/gas-oil-usage/create-item',
			name: 'gas-oil-usage.create',
			component: () =>
				lazyRetry(
					() => import(/* webpackChunkName: "gasOilUsage" */ '@/views/Transport/CreateGasOilUsage.vue')
				),
			meta: {
				auth: true
				// permission: 'vessel-logs.store',
			}
		},
		{
			path: '/gas-oil-usage/edit-item/:gasOilUsageId',
			name: 'gas-oil-usage.edit',
			props: true,
			component: () =>
				lazyRetry(
					() => import(/* webpackChunkName: "gasOilUsage" */ '@/views/Transport/CreateGasOilUsage.vue')
				),
			meta: {
				auth: true
				// permission: 'vessel-logs.update',
			}
		},
		{
			path: '/insight',
			name: 'dashboard-selection',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "dashboard" */ '@/views/DashboardSelection.vue')),
			meta: {
				auth: true
			}
		},
		{
			path: '/insight/dashboard',
			component: () => lazyRetry(() => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')),
			props: true,
			meta: {
				auth: true
			},
			children: [
				{
					path: '',
					name: 'dashboard.index',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Index.vue')),
					meta: {
						permission: 'insights.index',
						additional_details_required: true
					}
				},
				{
					name: 'dashboard.insights',
					path: 'insights',
					components: {
						default: () =>
							lazyRetry(
								() => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Insights.vue')
							),
						header: () =>
							lazyRetry(
								() =>
									import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/DashboardFilter.vue')
							)
					}
				},
				{
					name: 'dashboard.logs',
					path: 'logs',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/VesselLogs.vue'))
				},
				{
					name: 'dashboard.fleet',
					path: 'fleet',
					components: {
						fullPanel: () =>
							lazyRetry(() => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Fleet.vue'))
					}
				}
			]
		},
		{
			path: '/insight/dashboard-transport',
			name: 'dashboard-transport',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "dashboard-transport" */ '@/views/TransportDashboard.vue')),
			meta: {
				auth: true
			}
		},
		{
			path: '/inventory-management',
			name: 'inventory-management',
			component: () =>
				lazyRetry(
					() => import(/* webpackChunkName: "inventory-management" */ '@/views/InventoryManagement.vue')
				),
			meta: {
				auth: true
			}
		},
		{
			path: '/insight/port-management',

			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "port-management" */ '@/views/PortManagement.vue')),
			meta: {
				auth: true,
				permission: 'insights.index',
				additional_details_required: true
			},
			children: [
				{
					path: '',
					name: 'port-management',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "port-management" */ '@/views/PortDashboard.vue')),
					meta: {
						auth: true,
						permission: 'insights.index',
						additional_details_required: true
					}
				},
				{
					path: '/port-report',
					name: 'port-management.report',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "port-management" */ '@/views/PortReport.vue')),
					meta: {
						auth: true,
						permission: 'insights.index',
						additional_details_required: true
					}
				}
			]
		},
		{
			path: '/loading-bill',
			component: () => lazyRetry(() => import(/* webpackChunkName: "loading-bill" */ '@/views/LoadingBill.vue')),
			meta: {
				auth: true
				// permission: 'loading-bill.index',
				// additional_details_required: true,
			},
			children: [
				{
					path: '',
					name: 'loading-bill',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "loading-bill" */ '@/views/LoadingBill/Index.vue')),
					meta: {
						auth: true,
						fetchResources: [new BillOfLading().getJsonApiType()]
					}
				},
				{
					path: 'new',
					name: 'loading-bill.new',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "loading-bill" */ '@/views/LoadingBill/New.vue')),
					meta: {
						auth: true
					}
				}
			]
		},
		{
			path: '/invoicing',
			name: 'invoicing',
			component: () => lazyRetry(() => import(/* webpackChunkName: "invoicing" */ '@/views/Invoicing.vue')),
			meta: {
				auth: true,
				additional_details_required: true
			}
		},
		{
			path: '/terminals',
			name: 'terminals',
			component: () => lazyRetry(() => import(/* webpackChunkName: "terminals" */ '@/views/Terminals.vue')),
			meta: {
				auth: true,
				additional_details_required: true
			}
		},
		{
			path: '/depot',
			name: 'depot',
			component: () => lazyRetry(() => import(/* webpackChunkName: "depot" */ '@/views/Depot.vue')),
			meta: {
				auth: true,
				additional_details_required: true
			}
		},
		{
			path: '/capacity-radar',
			name: 'capacity-radar',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "capacity-radar" */ '@/views/CapacityRadar.vue')),
			meta: {
				auth: true,
				additional_details_required: true
			}
		},
		{
			path: '/profile',
			name: 'profile',
			component: () => lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')),
			meta: {
				auth: true,
				additional_details_required: true
			},
			children: [
				{
					path: '',
					name: 'profile.index',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Profile/Index.vue'))
				},
				{
					path: 'user',
					name: 'profile.user',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Profile/UserProfile.vue'))
				},
				{
					path: 'company',
					name: 'profile.company',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Profile/CompanyProfile.vue'))
				},
				{
					path: 'vessel',
					name: 'profile.vessel',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Profile/VesselProfile.vue'))
				},
				{
					path: 'connections',
					name: 'profile.connections',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Profile/Connections.vue'))
				},
				{
					path: 'management',
					name: 'profile.management',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Profile/UserManagement.vue'))
				}
			]
		},
		{
			path: '/profile/callback',
			name: 'profile.callback',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/ConnectionCallback.vue')),
			meta: {
				auth: true,
				additional_details_required: true
			}
		},
		{
			path: '/preferences',
			name: 'preferences',
			component: () => lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/UserPreferences.vue')),
			meta: {
				auth: true,
				additional_details_required: true
			},
			children: [
				{
					path: '',
					name: 'preferences.index',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Preferences/Index.vue'))
				},
				{
					path: 'notifications',
					name: 'preferences.notifications',
					component: () =>
						lazyRetry(
							() => import(/* webpackChunkName: "profile" */ '@/views/Preferences/Notifications.vue')
						)
				},
				{
					path: 'general',
					name: 'preferences.general',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Preferences/General.vue'))
				},
				{
					path: 'vessel',
					name: 'preferences.vessel',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Preferences/Vessel.vue'))
				},
				{
					path: 'documents',
					name: 'preferences.documents',
					component: () =>
						lazyRetry(
							() => import(/* webpackChunkName: "profile" */ '@/views/Preferences/VesselDocuments.vue')
						)
				},
				{
					path: 'charter',
					name: 'preferences.charter',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Preferences/Charter.vue'))
				},
				{
					path: 'invoice',
					name: 'preferences.invoice',
					component: () =>
						lazyRetry(() => import(/* webpackChunkName: "profile" */ '@/views/Preferences/Invoice.vue'))
				}
			]
		},
		{
			path: '/additional-information',
			name: 'additional-information',
			component: () =>
				lazyRetry(() => import(/* webpackChunkName: "additional-form" */ '@/views/AdditionalForm.vue')),
			meta: {
				auth: true
			},
			children: [
				{
					path: '/additional-information/company',
					name: 'additional-information.company',
					component: () =>
						lazyRetry(
							() => import(/* webpackChunkName: "additional-form" */ '@/views/AdditionalForm/Company.vue')
						)
				},
				{
					path: '/additional-information/vessel',
					name: 'additional-information.vessel',
					component: () =>
						lazyRetry(
							() => import(/* webpackChunkName: "additional-form" */ '@/views/AdditionalForm/Vessel.vue')
						)
				}
			]
		},
		{
			path: '/login',
			name: 'login',
			component: () => lazyRetry(() => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue'))
		},
		{
			path: '/register',
			name: 'register',
			component: () => lazyRetry(() => import(/* webpackChunkName: "guest" */ '@/views/Auth/Register.vue'))
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: () => lazyRetry(() => import(/* webpackChunkName: "guest" */ '@/views/Auth/ForgotPassword.vue'))
		},
		{
			path: '/reset',
			name: 'reset-password',
			component: () => lazyRetry(() => import(/* webpackChunkName: "guest" */ '@/views/Auth/ResetPassword.vue'))
		},
		{
			path: '/forbidden',
			name: 'forbidden',
			component: () => lazyRetry(() => import('@/views/Forbidden.vue'))
		},
		{
			path: '/not-found',
			name: 'notfound',
			component: () => lazyRetry(() => import('@/views/NotFound.vue'))
		},
		{
			path: '/active-pending',
			name: 'active-pending',
			component: () => lazyRetry(() => import('@/views/ActivePending.vue'))
		},
		{
			path: '/track-and-trace/:trackAndTraceId',
			name: 'trackandtrace',
			component: () => lazyRetry(() => import('@/views/TrackAndTrace.vue'))
		},
		{
			path: '/track-and-trace',
			name: 'create-trackandtrace',
			component: () => lazyRetry(() => import('@/views/TrackAndTrace.vue'))
		}
	]
})

router.beforeResolve(async (to, from, next) => {
	if (localStorage.getItem('forceLogin')) {
		// "Sync" the permissions and user so that we may intercept them during testing
		await Promise.all([Permission.sync(), User.sync()])
	}

	if (!localStorage.getItem('forceLogin') && to.matched.some((record) => record.meta.auth)) {
		const user = await getUser()

		if (!user) {
			return next({ name: 'login', query: { url: to.fullPath } })
		}
	}

	if (to.matched.some((record) => record.meta.additional_details_required)) {
		// @ts-ignore
		if (!store.state.auth.user.active) {
			await User.sync(true) // sync the user one last time to know for sure

			// @ts-ignore
			if (!store.state.auth.user.active) {
				return next({
					name: store.getters['auth/isActivePending'] ? 'active-pending' : 'additional-information.company'
				})
			}
		}

		if (store.getters['auth/userShouldRegisterVessel']) {
			return next({
				name: 'additional-information.vessel',
				query: { url: to.fullPath }
			})
		}
	}

	if (
		to.matched.some((route) => {
			return route.meta.permission && !store.getters['auth/hasPermission'](route.meta.permission)
		})
	) {
		return next({ name: 'forbidden' })
	}

	return next()
})

export default router
