import { ActionContext } from 'vuex'
import { DirectionPayload } from '@/directions/DirectionPayload'
import { Facility } from '@/models/Facility'
import { LatLngBounds } from 'leaflet'
import { MapState } from '@/interfaces/MapState'
import Notification from '@/classes/Notification'
import { PluralResponse } from 'coloquent'
import { RootState } from '@/store/RootState'
import { RoutePlanner } from '@/models/RoutePlanner'
import { Vessel } from '@/models/Vessel'
import i18n from '@/i18n/index'

export const setFacilities = async (
	context: ActionContext<MapState, RootState>,
	facilities: Array<Facility>
): Promise<void> => {
	context.commit('setFacilities', facilities)
}

export const setVessels = async (
	context: ActionContext<MapState, RootState>,
	vessels: Array<Vessel>
): Promise<void> => {
	context.commit('setVessels', vessels)
}

export const setQuery = async (context: ActionContext<MapState, RootState>, query: string | null): Promise<void> => {
	context.commit('setQuery', query)
}

export const setBoundingBox = async (
	context: ActionContext<MapState, RootState>,
	boundingBox: LatLngBounds | null
): Promise<void> => {
	context.commit('setBoundingBox', boundingBox)
}

export const calculateDirections = async (
	context: ActionContext<MapState, RootState>,
	directionPayload: DirectionPayload
): Promise<void> => {
	context.commit('setRoutesLoading', true)

	let builder = RoutePlanner.option('type', 'cost').option('transportType', 'vessel')

	Object.keys(directionPayload).forEach((key, index) => {
		if (typeof directionPayload[key] !== 'boolean') {
			directionPayload[key].getQueryParameters(index).forEach((parameter) => {
				builder = builder.option(parameter.key, parameter.value)
			})
		} else {
			builder = builder.option('withMultipleRoutes', String(directionPayload.withMultipleRoutes))
		}
	})

	const vessels = context.rootState.auth.vessels
	if (vessels.length) {
		builder = builder.option('vesselId', vessels[0])
	}

	try {
		const routes = ((await builder.get()) as PluralResponse).getData() as Array<RoutePlanner>
		const shipRoutes = routes.filter((route) => route.routeType === 'vessel')
		context.commit('setRoutes', shipRoutes)
	} catch {
		Notification.Error(i18n.t('map.directions.no_route_found').toString())
	}

	context.commit('setRoutesLoading', false)
}

export const setRoutes = async (
	context: ActionContext<MapState, RootState>,
	routes: Array<RoutePlanner>
): Promise<void> => {
	context.commit('setRoutes', routes)
}
