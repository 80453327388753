import AuthState from '@/interfaces/State/AuthState'
import { Company } from '@/models/Company'
import { NavigationItem } from '@/interfaces/NavigationItem'
import UserRole from '@/enums/UserRole'
import i18n from '@/i18n'

export const hasPermission = (state: AuthState): any => {
	return (permission: string) => {
		return state.permissions.includes(permission)
	}
}

const slugExists = (state: AuthState, slugName: string): boolean => {
	return state.memberships.some((m) => m === slugName)
}

export const hasDashboardMembership = (state: AuthState): boolean => slugExists(state, 'DBI')
export const hasDashboardTransportMembership = (state: AuthState): boolean => slugExists(state, 'DBT')
export const hasPortManagementMembership = (state: AuthState): boolean => slugExists(state, 'PMD')
export const hasHasInventoryManagementMembership = (state: AuthState): boolean => slugExists(state, 'VM')
export const hasLoadingBillMembership = (state: AuthState): boolean => slugExists(state, 'BLBL')
export const hasPrivateClientCommunicationMembership = (state: AuthState): boolean => slugExists(state, 'PCC')
export const hasAccountingMembership = (state: AuthState): boolean => slugExists(state, 'ACC')
export const hasThirdPartyCollaborationsMembership = (state: AuthState): boolean => slugExists(state, 'TPC')
export const hasFinancialRegistrationMembership = (state: AuthState): boolean => slugExists(state, 'TFR')
export const hasBillOfLadingTransportMembership = (state: AuthState): boolean => slugExists(state, 'BLT')
export const hasAdvisingDocumentMembership = (state: AuthState): boolean => slugExists(state, 'AD')
export const hasLCIMembership = (state: AuthState): boolean => slugExists(state, 'LCI')
export const hasEMLMembership = (state: AuthState): boolean => slugExists(state, 'EML')
export const hasOBMembership = (state: AuthState): boolean => slugExists(state, 'OB')
export const hasCCRMembership = (state: AuthState): boolean => slugExists(state, 'CCR')
export const hasDPTMembership = (state: AuthState): boolean => slugExists(state, 'DPT')

export const hasMapPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PM')
export const hasCargoPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PC')
export const hasTransportPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PT')
export const hasMessageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PMSG')
export const hasGroupsPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PG')
export const hasInsightPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'PI')
export const hasCapacityRadarMembership = (state: AuthState): boolean => slugExists(state, 'CR')
export const hasTerminalPageAccessMembership = (state: AuthState): boolean => slugExists(state, 'TM')

export const isInitialized = (state: AuthState): boolean => !!state.initialized
export const loggedIn = (state: AuthState): boolean => !!state.user.id
export const spoofed = (state: AuthState): boolean => !!state.user.spoofed
export const currentRole = (state: AuthState): string => state.user.role
export const isSkipper = (state: AuthState): boolean => state.user.role === UserRole.SKIPPER
export const isPortManager = (state: AuthState): boolean => state.user.role === UserRole.PORTMANAGER
export const isBroker = (state: AuthState): boolean => state.user.role === UserRole.LOGISTICSERVICEPROVIDER
export const isShipper = (state: AuthState): boolean => state.user.role === UserRole.SHIPPER
export const hasInternalFinancialConnection = (state: AuthState): boolean =>
	!!(state.financialConnection && state.financialConnection.connection === 'internal')
export const hasExactFinancialConnection = (state: AuthState): boolean =>
	!!(state.financialConnection && state.financialConnection.connection === 'exact-online')

export const userShouldRegisterVessel = (state: AuthState): boolean => {
	return state.user.role === UserRole.SKIPPER && state.vessels.length === 0
}

export const isActivePending = (state: AuthState): boolean => {
	if (!state.companies || state.companies.length === 0 || state.user.active) {
		return false
	}

	return (
		state.companies.filter((company) => {
			return Number(company.cocNumber) > 0
		}).length > 0
	)
}

export const currentCompany = (state: AuthState): Company => {
	return state.companies[0]
}

export const navigationItems = (state: AuthState, getters): Array<NavigationItem> => {
	return [
		{
			title: i18n.t('navigation.map').toString(),
			icon: 'map-signs',
			routeName: 'map',
			slug: 'map',
			hasPermission: getters.hasPermission('maps.index'),
			hasMembership: getters.hasMapPageAccessMembership
		},
		{
			title: i18n.t('navigation.cargo').toString(),
			icon: 'cubes',
			routeName: 'cargo',
			slug: 'cargo',
			hasPermission: getters.hasPermission('offers.index'),
			hasMembership: getters.hasCargoPageAccessMembership
		},
		{
			title: i18n.t('navigation.capacityRadar').toString(),
			icon: 'radar-solid',
			routeName: 'capacity-radar',
			slug: 'capacity-radar',
			hasPermission: true,
			hasMembership: getters.hasCapacityRadarMembership
		},
		{
			title: i18n.t('navigation.transport').toString(),
			icon: 'ship',
			routeName: 'transports',
			slug: 'transport',
			hasPermission: getters.hasPermission('transports.index'),
			hasMembership: getters.hasTransportPageAccessMembership
		},
		{
			title: i18n.t('navigation.loading_bill').toString(),
			icon: 'receipt-long',
			routeName: 'loading-bill',
			slug: 'loading-bill',
			hasPermission: true,
			hasMembership: getters.hasLoadingBillMembership
		},
		{
			title: i18n.t('navigation.terminals').toString(),
			icon: 'tour',
			routeName: 'terminals',
			slug: 'terminals',
			hasPermission: true,
			hasMembership: getters.hasTerminalPageAccessMembership
		},
		{
			title: i18n.t('navigation.depot').toString(),
			icon: 'warehouse',
			routeName: 'depot',
			slug: 'depot',
			hasPermission: true,
			hasMembership: getters.hasDPTMembership
		},
		{
			title: i18n.t('navigation.messages').toString(),
			icon: 'message-bubble',
			routeName: 'messages',
			slug: 'message',
			hasPermission: getters.hasPermission('messages.index'),
			hasMembership: getters.hasMessageAccessMembership
		},
		{
			title: i18n.t('navigation.groups').toString(),
			icon: 'star',
			routeName: 'groups',
			slug: 'group',
			hasPermission: getters.hasPermission('fleetMembers.index'),
			hasMembership: getters.hasGroupsPageAccessMembership
		},
		{
			title: i18n.t('navigation.dashboardInsights').toString(),
			icon: 'dashboard',
			routeName: 'dashboard-selection',
			slug: 'dashboard',
			hasPermission: getters.hasPermission('insights.index'),
			hasMembership: getters.hasInsightPageAccessMembership
		},
		{
			title: i18n.t('navigation.financial').toString(),
			icon: 'euro-sign',
			routeName: 'invoicing',
			slug: 'invoicing',
			hasPermission: true,
			hasMembership: getters.hasAccountingMembership,
			additionalFilter: () => getters.hasInternalFinancialConnection
		}
	].filter(
		(navigationItem) =>
			navigationItem.hasPermission &&
			navigationItem.hasMembership &&
			(navigationItem.additionalFilter ? navigationItem.additionalFilter() : true)
	)
}
